import { Location } from '@angular/common';
import { AfterViewInit, Component, HostBinding, inject, QueryList, ViewChildren } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { NavigationEnd } from '@angular/router';
import { ApiUser } from '@tytapp/api';
import { AppConfig, AudienceType, PageComponent, PeriodicTaskService, SiteWideAlert, sleep, Transfer } from '@tytapp/common';
import { UserService } from '@tytapp/user';
import { UserAuthMediatorComponent } from '@tytapp/user/user-auth-mediator/user-auth-mediator.component';
import { environment } from '@tytapp/environment';
import { GoogleCastService } from '@tytapp/media-playback';
import { Subscription } from 'rxjs';
import { HostApi } from '@tytapp/common';
import { isClientSide, rewriteUrl } from '@tytapp/environment-utils';
import { BillingService } from '@tytapp/billing';
import { LiveStreamsService } from '@tytapp/live-streams';
import { NotificationsService } from '@tytapp/notifications';

export interface NavItem {
    id: string;
    label: string;
    url?: string;
    action?: (event?: Event) => void;
    icon?: string;
    external?: boolean;
    audience?: AudienceType[];
    requiresCapabilities?: string[];
    class?: string;
    visible?: boolean;
}

@Component({
    selector: 'tyt-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent extends PageComponent implements AfterViewInit {
    // TODO: this class should not be PageComponent but it relies on state transfer

    private location = inject(Location);
    private userService = inject(UserService);
    private liveStreamsService = inject(LiveStreamsService);
    private castService = inject(GoogleCastService);
    private appConfig = inject(AppConfig);
    private notifications = inject(NotificationsService);
    private hostApi = inject(HostApi);
    private billing = inject(BillingService);
    private periodicTasks = inject(PeriodicTaskService);

    constructor() {
        super();
        this.subscription = this.router.events.subscribe(s => {
            if (s instanceof NavigationEnd) {
                this.update();
            }
        });
    }

    primaryMenu: NavItem[] = [
        { id: 'home', label: 'Home', url: '/' },
        { id: 'live', label: 'Live', url: '/live' },
        { id: 'shows', label: 'Shows', url: '/shows' },
        { id: 'podcasts', label: 'Podcasts', url: '/podcasts' },
        { id: 'reports', label: 'Reports', url: '/reports' },
        { id: 'nation', label: 'TYT Nation', url: '/nation/home', visible: false },
        { id: 'resources', label: 'Resources', url: '/resources' },
    ];

    capabilities: string[];

    isCapableOf(caps?: string[]) {
        if (!caps)
            return true;
        if (!this.capabilities)
            return false;
        return caps.every(cap => {
            if (cap === 'billing:membership')
                return this.capabilities.includes('web_billing:membership') || this.capabilities.includes('platform_billing:membership');
            return this.capabilities.includes(cap);
        });
    }

    secondaryMenu: NavItem[] = [
        {
            id: 'donate',
            label: 'Donate',
            url: environment.urls.donate,
            audience: ['member', 'staff'],
            requiresCapabilities: ['web_billing:contribution'],
            external: true
        },
        {
            id: 'shop',
            label: 'TYT Store',
            url: 'https://shoptyt.com/',
            external: true
        },
        {
            id: 'join',
            label: 'Join',
            url: `/join/membership`,
            audience: ['visitor', 'registered'],
            requiresCapabilities: [ 'billing:membership' ],
            external: false
        },
        {
            id: 'sign-in',
            label: 'Sign in',
            url: '/login',
            action: ($event) => {
                $event.preventDefault();
                $event.stopPropagation();
                this.showSignIn($event)
            },
            audience: ['visitor'],
        }
    ];

    mobileMenu: NavItem[] = [
        {
            id: 'home',
            label: 'Home',
            icon: 'home',
            url: '/'
        },
        {
            id: 'live',
            label: 'Watch Live',
            icon: 'live_tv',
            url: '/live'
        },
        {
            id: 'watch-on-demand',
            label: 'Watch On Demand',
            icon: 'skip_next',
            url: '/shows/all'
        },
        {
            id: 'resources',
            label: 'Resources',
            icon: 'info',
            url: '/resources'
        },
        {
            id: 'downloads',
            label: 'Downloads',
            icon: 'download',
            url: '/downloads'
        },
        {
            id: 'shows',
            label: 'Shows',
            icon: 'tv',
            url: '/shows',
        },
        {
            id: 'podcasts',
            label: 'Podcasts',
            icon: 'podcasts',
            url: '/podcasts',
        },
        {
            id: 'reports',
            label: 'Reports',
            icon: 'article',
            url: '/reports',
        },
        {
            id: 'topics',
            label: 'Topics',
            icon: 'topic',
            url: '/topics'
        },
        {
            id: 'impacts',
            label: 'Impacts',
            icon: 'campaign',
            url: '/impacts',
        },
        {
            id: 'nation',
            label: 'TYT Nation',
            icon: 'groups',
            url: '/nation/home',
            visible: false
        },
        {
            id: 'settings',
            label: 'Settings',
            icon: 'settings',
            url: '/settings',
            audience: ['registered', 'staff', 'member']
        },
        {
            id: 'become-member',
            label: 'Become a Member',
            icon: 'card_membership',
            url: '/join/membership',
            requiresCapabilities: ['billing:membership'],
            visible: false
        },
        {
            id: 'restore-purchases',
            label: 'Restore Purchases',
            icon: 'restore',
            requiresCapabilities: ['platform_billing:restore_purchases'],
            action: () => this.billing.restorePurchases(),
            visible: true
        },
        {
            id: 'shop',
            label: 'TYT Store',
            icon: 'shopping_cart',
            url: 'https://shoptyt.com',
            external: true,
            visible: true
        },
        {
            id: 'about',
            label: 'About',
            icon: 'info',
            url: '/about',
            visible: true
        }
    ];

    @HostBinding('class.animate-in')
    animateIn = false;

    get casting() {
        return this.castService.connected;
    }

    get pageComponentId() {
        return 'tyt-nav';
    }

    dismissAlert(alert: SiteWideAlert) {
        this.shell.dismissAlert(alert.id);
    }

    get audience() {
        if (this.user?.staff)
            return 'staff';
        if (this.entitled)
            return 'member';
        if (!this.user)
            return 'visitor';

        return 'registered';
    }

    activateItem(item: NavItem, event: Event) {
        this.hideSidebar();
        item.action?.(event);
    }

    shouldShowNav(item: NavItem) {
        if (item.visible === false)
            return false;

        if (item.audience && !item.audience.includes(this.audience))
            return false;
        if (!this.isCapableOf(item.requiresCapabilities))
            return false;
        return true;
    }

    sidebarVisible: boolean = false;
    sidebarHidden: boolean = true;
    highlightMap = {};
    siteWideMessage = '';
    siteWideUrl: SafeHtml = null;
    notificationsEnabled = false;
    notificationsEnabledAlways = false;

    private subscription: Subscription;

    async signOut() {
        this.userService.completeLogout();
        this.userService.user = null;
        this.router.navigate(['home']);
    }

    get showNotifications() {
        if (!this.notificationsEnabled)
            return false;
        return this.notifications.count > 0 || this.notificationsEnabledAlways;
    }

    get accountsUrl() {
        return environment.urls.accounts;
    }

    get userDisplayName() {
        if (!this.user)
            return null;

        if (this.user.profile) {
            if (this.user.profile.display_name)
                return this.user.profile.display_name;

            if (this.user.profile.first_name)
                return this.user.profile.first_name;
        }

        if (this.user.display_name)
            return this.user.display_name;

        return this.user.email;
    }

    alerts: SiteWideAlert[] = [];

    toggleSidebar() {
        if (this.sidebarVisible)
            this.hideSidebar();
        else
            this.showSidebar();

        this.notifications.closeList();
    }

    finishSidebarChange;

    showSidebar() {
        this.sidebarHidden = false;
        clearTimeout(this.finishSidebarChange)
        this.finishSidebarChange = setTimeout(() => this.sidebarVisible = true);
        document.documentElement.style.overflow = 'hidden';
    }

    hideSidebar() {
        if (!this.sidebarVisible)
            return;

        // a11y: Important to fully hide the sidebar so that its removed from the tab order when not visible
        this.sidebarVisible = false;

        if (isClientSide()) {
            clearTimeout(this.finishSidebarChange)
            this.finishSidebarChange = setTimeout(() => this.sidebarHidden = true, 250);
            document.documentElement.style.overflow = '';
        }
    }

    async init() {
        this.animateIn = true;

        await this.hostApi.capabilitiesReady;
        this.capabilities = this.hostApi.capabilities;

        this.shell.alertsChanged.subscribe(alerts => this.alerts = alerts);
        this.notifications.visibleChange.subscribe(visible => {
            if (visible) {
                this.hideSidebar();
            }
        });
        this.notificationsEnabled = await this.appConfig.featureEnabled('apps.web.enable_notifications');
        this.notificationsEnabledAlways = await this.appConfig.featureEnabled('apps.web.enable_notifications_always');

        this.router.events.subscribe(ev => {
            this.hideSidebar();
        });

        this.subscribe(this.billing.entitlementChanged, async (entitled) => {
            this.entitled = entitled;
            this.mobileMenu.find(x => x.id === 'become-member').visible = !entitled;
            this.secondaryMenu.find(x => x.id === 'join').visible = !entitled;
        });

        this.subscribe(this.userService.userChanged, async (user) => {
            if (!this.hasServerState) {
                if (user && user.membership) {
                    if (user.membership.payment_status === 'past_due') {
                        this.isPastDue = true;
                    }
                }

                this.user = user;
            }

            const nationDesktop = this.primaryMenu.find(x => x.id === 'nation');
            const nationMobile = this.mobileMenu.find(x => x.id === 'nation');

            if (user?.can_access_tytnation) {
                nationDesktop.visible = nationMobile.visible = true;
            } else {
                const enable_tytn = await this.shell.hasFeature('apps.web.enable_tytn');
                if (enable_tytn) {
                    const closedBeta = !await this.shell.hasFeature('apps.web.nation_open_beta');
                    const visible = closedBeta && !this.user?.can_access_tytnation;
                    nationDesktop.visible = nationMobile.visible = visible;
                }
            }
        });

        await this.userService.ready;
        this.setupLiveStreamBanner();

        if (environment.isNativeBuild || environment.showDevTools)
            this.rewriteExternalLinks();

        if (isClientSide())
            await sleep(1);
    }

    private async setupLiveStreamBanner() {
        if (isClientSide()) {
            document.addEventListener('visibilitychange', async () => {
                if (document.visibilityState === 'visible') {
                    this.updateLiveStreamBanner();
                }
            });

            this.hostApi.messageReceived.subscribe(async message => {
                if (message.type === 'visible') {
                    this.updateLiveStreamBanner();
                }
            });

            this.periodicTasks.schedule(
                60*4*1000 + Math.random() * 30_000,
                () => this.updateLiveStreamBanner()
            );
        }

        await this.updateLiveStreamBanner();
    }

    private liveStreamBannerDismissedAt: number = 0;
    private liveStreamBannerDismissTimeout = 1000 * 30;

    private async updateLiveStreamBanner() {
        if (this.liveStreamBannerDismissedAt + this.liveStreamBannerDismissTimeout > Date.now())
            return;

        try {
            this.isLive = await this.liveStreamsService.getLiveIndicator();
            this.updateNavItem('live', { class: this.isLive ? 'live' : undefined });
            if (this.isLive && !this.location.path().startsWith('/live')) {
                this.shell.addAlert({
                    id: 'watch-live',
                    message: 'Watch Live Now',
                    backgroundColor: '#99161a', // A11y/WCAG sensitive: contrast ratio against white
                    textColor: 'white',
                    internal: true,
                    icon: 'live_tv',
                    type: 'info',
                    url: '/live',
                    onDismiss: () => this.liveStreamBannerDismissedAt = Date.now()
                })
            }

        } catch (e) {
            this.logger.error(`While fetching the live streams in NavComponent:`);
            this.logger.error(e);
        }
    }


    rewriteExternalLinks() {
        if (!isClientSide())
            return;

        document.documentElement.addEventListener('click', e => {
            // First, allow alt key (typically "download the page" instead of opening it) to work as normal (will
            // download original untransformed URL)
            if (e.altKey)
                return;

            let openInNewTab = e.ctrlKey || e.metaKey;

            try {
                let link = (e.target as HTMLElement).closest('a');
                let originalUrl = new URL(link?.href || location.href);
                let urlString = rewriteUrl(environment, link?.href);
                let wasInternal = originalUrl.origin === location.origin;
                let isRouterLink = link?.classList?.contains('router-link') ?? false;
                let isUniversalLink = link?.classList?.contains('universal-link') ?? false;
                let skipRewrite = link?.classList?.contains('skip-rewrite') ?? false;

                if (!link || skipRewrite || link?.download)
                    return;

                if (isRouterLink || isUniversalLink) {
                    // Prevent "new tab" router links from escaping the app.
                    if (environment.isNativeBuild && link.target === '_blank') {
                        link.target = '';
                    }
                    return;
                }

                if (urlString && !urlString.startsWith('javascript:') && !urlString.startsWith('mailto:')) {
                    let url = new URL(urlString);
                    let isInternal = url.origin === location.origin;
                    const isSamePath = (url.pathname === location.pathname);

                    // If the rewritten URL should be handled by us.
                    if (isInternal && !isSamePath && (!wasInternal || !isRouterLink)) {
                        //e.stopImmediatePropagation(); // this would block (click) handling unnecessarily
                        e.preventDefault();

                        // Since we are so late in the link processing chain, any URL that has been rewritten
                        // from an external jump (ie from a preprod URL to prod URL) will have been tagged by the
                        // GA linker system with the `_gl` session linking parameter.
                        //
                        // Since we are not actually changing sites, we do not need this GA linking parameter, so
                        // let's rip it off.

                        url.searchParams.delete('_gl');

                        let destination = `${url.pathname}${url.search}${url.hash}`;
                        openInNewTab ||= link.getAttribute('target') === '_blank';

                        /**
                         * On native builds, we never want to open internal "app" links in a new tab, because doing
                         * so will trigger the shell to open a normal web browser.
                         */
                        if (environment.isNativeBuild)
                            openInNewTab = false;

                        this.logger.info(`[Link Rewriting] Rewrote link from '${originalUrl}' to ${url.toString()}`);
                        if (openInNewTab) {
                            window.open(url.toString(), '_blank');
                        } else {
                            // We use redirection.go() here passing false for replaceUrl (since this is not actually
                            // a redirect action) to ensure we handle query parameters correctly.
                            this.redirection.go(destination, false);
                        }
                    } else {
                        if (environment.isNativeBuild && url.toString().startsWith(environment.urls.forums)) {
                            url = new URL(`${environment.urls.platform}/auth/web?return_url=${encodeURIComponent(url.toString())}&jwt=${encodeURIComponent(this.userService.token)}`);

                            this.logger.info(`[Link Rewriting] Forums link '${originalUrl}' being rewritten to include authentication sync information: '${url.toString()}'`);

                            e.preventDefault();
                            location.assign(url);
                        }
                    }
                }
            } catch (e) {
                this.logger.error(`Failed while guarding external links:`);
                this.logger.error(e);
            }
        });
    }

    get alertWideUrl() {
        return this.siteWideUrl || 'javascript:;';
    }

    getNavItem(id: string) {
        return [].concat(this.primaryMenu, this.secondaryMenu).find(x => x.id === id);
    }

    updateNavItem(id: string, update: Partial<NavItem>) {
        let item = this.getNavItem(id);
        if (!item)
            return;

        Object.assign(item, update);
        this.itemClassCache.delete(item);
    }

    itemClassCache = new WeakMap<NavItem, any>();

    classesForItem(item: NavItem) {
        if (!this.itemClassCache.has(item)) {
            this.itemClassCache.set(item, {
                [item.class]: true,
                highlight: this.highlightMap[item.id]
            });
        }

        return this.itemClassCache.get(item);
    }

    update() {
        let currentUrl = this.router.url;
        let urlArray = currentUrl.split('/');
        this.highlightMap = {};

        if (urlArray.length >= 2 && ['shows', 'watch', 'listen'].includes(urlArray[1])) {
            this.highlightMap['shows'] = true;
        }
    }

    @Transfer()
    user: ApiUser;

    @Transfer()
    entitled: boolean;

    @Transfer()
    guest: ApiUser;

    @Transfer()
    isLive: boolean = false;

    @Transfer()
    isPastDue: boolean = false;

    ngAfterViewInit() {
    }

    destroy(): any { this.subscription.unsubscribe(); }

    get hasMembership() {
        return !!this.user?.membership;
    }

    get joinUrl() {
        return `/join/membership`;
    }

    showSignIn(event: Event): void {
        event.preventDefault();
        this.shell.showDialog(UserAuthMediatorComponent);
    }

    toggleNotificationDrawer(): void {
        if (!this.notifications.visible) {
            window.history.pushState({
                type: 'notifications',
                dialog: 'NotificationListComponent'
            }, '');
            this.notifications.visible = true;
        } else {
            this.notifications.visible = false;
        }
    }

    get notificationsCount() {
        return this.notifications.unseen;
    }

    get notificationsVisible() {
        return this.notifications.visible;
    }

    get environment() {
        return environment;
    }

    get siteWideAlertOffset() {
        return this.shell.siteWideAlertOffset;
    }

    envColors = {
        development: ['darkgreen', 'white'],
        staging: ['purple', 'white'],
        production: ['#ff1919', 'black'],
        'native-preproduction': ['#376163', 'white'],
        'native-production': ['#ff1919', 'black'],
    };

    get envHintBackgroundColor() {
        if (!environment.showEnvironmentHint)
            return undefined;
        return this.envColors[environment.apiOverride || environment.name]?.[0] || 'yellow';
    }

    get envHintTextColor() {
        if (!environment.showEnvironmentHint)
            return undefined;
        return this.envColors[environment.apiOverride || environment.name]?.[1] || 'black';
    }

    get envMessage() {
        if (!environment.showEnvironmentHint)
            return undefined;

        let names = {
            development: 'dev',
            staging: 'stg',
            production: 'prod',
            'native-production': 'native-production',
            'native-preproduction': 'native-preproduction'
        };

        let str = names[environment.name] || environment.name;
        if (environment.apiOverride && environment.apiOverride !== environment.name)
            str += ` | API: ${environment.apiOverride}`

        if (environment.version)
            str += ` | V: ${environment.version}`;

        return str;
    }

    hideEnvironmentHint = false;
}
