<nav>
  <a
    class="show-on-focus"
    aria-label="Skip to content"
    routerLink="."
    fragment="main-content"
    (click)="skipToContent($event)"
  >Skip to content</a>
</nav>

@if (updating) {
  <div class="updating">
    <app-spinner class="first"></app-spinner>
    <app-spinner class="second"></app-spinner>
    <img src="/assets/membership-benefits/tyt_shield_logo_icon.svg">
  </div>
}

@if (environment.showDevTools) {
  <tyt-dev-tools></tyt-dev-tools>
}
<tyt-cast-sender></tyt-cast-sender>
<tyt-airplay-sender></tyt-airplay-sender>

@if (!isServer) {
  <tyt-media-player
    #player
    [allowKeyboardControls]="false"
  ></tyt-media-player>
  @if (appPlayingAudio) {
    <div id="podcast-player">
      <a href="javascript:;" (click)="closePodcast()" aria-label="Close podcast" class="close-button">
        <mat-icon>close</mat-icon>
      </a>
      <tyt-remote-audio-player></tyt-remote-audio-player>
    </div>
  }
}

<dialog id="dialog-host"
  [class.visible]="dialogVisible"
  [class.switching-away]="switchingDialog"
  [class.switching-back]="switchingDialogBack"
  [class.hidden]="dialogHidden"
  [attr.aria-hidden]="!dialogVisible"
  >
  @if (dialogClosable && clientSide) {
    <a href="javascript:;" (click)="closeDialog()" class="dialog-close" aria-label="Close">
      <mat-icon>close</mat-icon>
    </a>
  }
  <div #dialogAnchor></div>
</dialog>

<div id="dialog-background" (click)="hideDialog()" [class.active]="dialogVisible">
</div>
@if (!noLayout) {
  <header #mainHeader>
    <tyt-nav #nav></tyt-nav>
  </header>
}
@if (networkError) {
  <main id="main-content" [class.chrome-flex]="noLayout || isChrome" [attr.aria-hidden]="dialogVisible">
    <section class="server-down">
      @if (networkErrorType === 'offline') {
        <svg width="285px" height="306px" viewBox="13 0 285 306" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Group-3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(13.000000, 0.000000)">
            <polygon id="Path" fill="#FFFFFF" points="256.351585 91.6642857 213.916427 91.6642857 213.916427 80.0385714 284.492795 80.0385714 284.492795 14.3085714 270.198847 14.3085714 270.198847 0 155.400576 0 155.400576 14.3085714 141.553314 14.3085714 141.553314 105.972857 127.259366 105.972857 127.259366 119.834286 106.26513 119.834286 106.26513 134.142857 85.2708934 134.142857 85.2708934 148.451429 70.9769452 148.451429 70.9769452 162.312857 45.0691643 162.312857 45.0691643 148.004286 31.221902 148.004286 31.221902 134.142857 16.9279539 134.142857 16.9279539 105.972857 0.847262248 105.972857 0.847262248 192.718571 14.6945245 192.718571 14.6945245 207.027143 28.9884726 207.027143 28.9884726 220.888571 42.8357349 220.888571 42.8357349 235.197143 57.129683 235.197143 57.129683 249.058571 70.9769452 249.058571 70.9769452 305.398571 101.351585 305.398571 101.351585 289.301429 87.5043228 289.301429 87.5043228 277.228571 101.351585 277.228571 101.351585 263.367143 115.645533 263.367143 115.645533 249.058571 127.259366 249.058571 127.259366 263.367143 141.553314 263.367143 141.553314 305.398571 171.927954 305.398571 171.927954 289.301429 157.634006 289.301429 157.634006 235.197143 171.927954 235.197143 171.927954 220.888571 185.775216 220.888571 185.775216 199.872857 200.069164 199.872857 200.069164 150.687143 211.682997 150.687143 211.682997 164.548571 228.210375 164.548571 228.210375 134.142857 200.069164 134.142857 200.069164 108.208571 256.351585 108.208571"></polygon>
            <rect id="Rectangle" fill="#000000" x="169.247839" y="20.5685714" width="16.9740634" height="16.9914286"></rect>
          </g>
        </svg>
        <h1>No Internet connection</h1>
        <p>Looks like this content has not yet been downloaded to your device,
          but there is a problem connecting to the Internet to download it.
          Make sure your connection is working and try again. If your Internet
          connection is working properly, there may be a problem with our
        servers. If so, please try again later.</p>
        @if (downloadsEnabled) {
          <p>If you have previously downloaded content to watch offline, visit the
          Downloads area.</p>
        }
        <div class="actions">
          <a mat-raised-button color="primary" href="javascript:;" onclick="window.location.reload()">Try Again</a>
          @if (downloadsEnabled) {
            <a mat-raised-button color="primary" routerLink="/downloads">View Downloads</a>
          }
        </div>
        <tyt-staging-note title="Cause of Offline Error" [shown]="true">
          {{ offlineErrorCause }}
        </tyt-staging-note>
      }
      @if (networkErrorType === 'serverDown') {
        <img src="/assets/privacy/attention.svg" />
        <h1>
          @if (showDevTools) {
            Cannot contact TYT Platform
          }
          @if (!showDevTools) {
            Uh oh, we're having some trouble
          }
        </h1>
        <p>Looks like a problem with our backend servers and this content hasn't been synced to your device yet.
          Please try again later. If the problem persists, please contact
          <a href="mailto:support@tytnetwork.com">support&#64;tytnetwork.com</a>.
        </p>
        @if (showDevTools) {
          <div>
            <p>You are using a pre-production version of TYT.com. If you are not a member of the Product &amp; Engineering team you may have intended to use
              the <strong>public version</strong> of TYT.com. <a href="https://tyt.com/">Click here to return to the public version of TYT.com</a>.
            </p>
            <p>Product &amp; Engineering team members may wish to override the Platform environment this app should connect to:</p>
            <div class="actions">
              <a mat-raised-button color="primary" href="javascript:;" (click)="overrideApi('')">Dev</a>
              <a mat-raised-button color="primary" href="javascript:;" (click)="overrideApi('staging')">Staging</a>
              <a mat-raised-button color="primary" href="javascript:;" (click)="overrideApi('tytdev')">TYTDev</a>
              <a mat-raised-button color="primary" href="javascript:;" (click)="overrideApi('production')">Production</a>
            </div>
          </div>
        }
        @if (!showDevTools) {
          <div class="actions">
            <a mat-raised-button color="primary" href="javascript:;" onclick="window.location.reload()">Try Again</a>
            <a mat-raised-button color="primary" href="mailto:support@tytnetwork.com">Contact Support</a>
          </div>
        }
        <br />
        <br />
        <h2>Should I check my Internet connection?</h2>
        It looks like your Internet connection is working properly, but it is possible that there is a problem
        on your network that is specifically affecting TYT.com. Sometimes corporate networks, firewalls, or
        browser extensions can interfere.
        <p>If you suspect the issue may be on your end, here are some troubleshooting steps to try:</p>
        <ul>
          <li>To rule out your network as the problem, try visiting TYT.com from a different Internet
          connection if you can</li>
          <li>To determine if there's a compatibility problem with your browser, try using a different
            browser if you can.
          </li>
          <li>To rule out a browser extension as the cause, try visiting TYT.com from an
            Incognito, Private Browsing or InPrivate browsing session. Browsers typically
            disable all extensions when you use a private browsing session. If TYT.com works
            properly in the private browsing session, then a browser extension is likely to blame.
            Try turning them all off and enabling them one by one until TYT.com stops working to
            determine which browser extension is causing the problem.
          </li>
        </ul>
        <h2>Should I clear my cache and cookies?</h2>
        <p>There are very few situations where clearing your cache and cookies would solve an issue like this,
          and the pain of logging back in to all of your services means you should probably only try it as a last
          resort. Furthermore, if the site isn't working within a private browsing session, it's very unlikely
          that clearing your cache and cookies will help. That being said, if you have exhausted other troubleshooting
          steps, it may be worth a shot.
        </p>
      }
      @if (networkErrorType === 'networkError') {
        <img src="/assets/privacy/attention.svg" />
        <h1>Network Error</h1>
        <p>Looks like we're having trouble reaching TYT.com. Looks like your Internet connection may be down or there is a problem
          on your local network. Make sure your connection is working and try again. Make sure TYT.com is not blocked on your device's
        or organization's firewall</p>
        <p>
          If you are able to visit other websites then may be a problem with our servers.
          If so, please try again later or contact
          <a href="mailto:support@tytnetwork.com">support&#64;tytnetwork.com</a>.</p>
          <div class="actions">
            <a mat-raised-button color="primary" href="javascript:;" onclick="window.location.reload()">Try Again</a>
            <a mat-raised-button color="primary" href="mailto:support@tytnetwork.com">Contact Support</a>
          </div>
        }
      </section>
    </main>
  }

  <main #mainContent id="main-content" [class.hidden]="networkError" [class.chrome-flex]="noLayout || isChrome">
    <router-outlet></router-outlet>
  </main>

  @if (!noLayout && !environment.isNativeBuild) {
    <footer id="main-footer">
      <tyt-staging-note only="staging" [shown]="true">
        This is the TYT staging installation. It is a separate installation which does not have the latest
        content and data that is available from the production website.
      </tyt-staging-note>

      <div class="footer-nav">
        <div class="tyt-network">
          <div><a class="link" routerLink="/how-to-watch">How to Watch TYT</a></div>
          <div><a class="link" href="https://help.tyt.com" target="_blank">Get Help / Contact Us</a></div>
          <div><a class="link" routerLink="/redeem">Redeem a Sponsorship</a></div>
          <div><a class="link" routerLink="/archive">Show Archive</a></div>
          <div>
            @if (billingEnabled) {
              <a class="link" target="_blank" href="https://secure.actblue.com/donate/tytnetwork">Donate To TYT</a>
            }
          </div>
        </div>
        <div class="social">
          <a class="follow-button youtube" href="https://youtube.com/theyoungturks" target="_blank">
            <img width="42" height="42" alt="YouTube logo" src="/assets/social-icons/youtube_icon.svg" />
          </a>
          <a class="follow-button twitter" href="https://twitter.com/tyt" target="_blank">
            <img width="42" height="42"  alt="Twitter logo" src="/assets/community/twitter-logo.svg" />
          </a>
          <a class="follow-button facebook" href="https://facebook.com/theyoungturks" target="_blank">
            <img width="42" height="42"  alt="Facebook logo" src="/assets/community/facebook-logo.svg" />
          </a>
          <a class="follow-button twitch" href="https://twitch.tv/tyt" target="_blank">
            <img width="42" height="42"  alt="Twitch logo" src="/assets/social-icons/twitch_icon.svg" />
          </a>
        </div>
        <div class="contact">
          <div><a class="link" routerLink="/about">About Us</a></div>
          <div><a class="link" routerLink="/impacts">Our Impacts</a></div>
          <div><a class="link" routerLink="/about/press">Press</a></div>
          <div><a class="link" routerLink="/careers">Careers</a></div>
          <div><a class="link" routerLink="/about/honor-roll">TYT Honor Roll</a></div>
        </div>
      </div>
    </footer>
  }

  <footer>
    @if (!noLayout && !environment.isNativeBuild) {
      <tyt-site-footer></tyt-site-footer>
    }
    <div style="pointer-events: none; opacity: 0; height: 1em; overflow: hidden;" aria-live="polite">{{politeAnnouncement}}</div>
  </footer>