/**
 * TYT 2.0 APP MODULE
 */

/* CORE MODULES */

import { provideHttpClient, withFetch } from "@angular/common/http";
import { APP_ID, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions } from "@angular/platform-browser";
import {
    RouteReuseStrategy,
    RouterModule,
} from "@angular/router";

/* THIRD PARTY */

import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";

/* MISC */

import { ROUTES } from "./routes";

/* DECLARATIONS */

import { AppComponent } from "./app.component";
import { AppErrorHandler } from "./error-handler";
export { AppComponent };

    import { LogoutComponent } from "./logout/logout.component";

/* FEATURES */

import { ApiConfiguration, ApiModule } from "../api";
import { TYTCommonModule } from "../common";
import { MediaPlaybackModule } from "../media-playback";
import { UserModule } from "../user";

import { ApiConfigurationFactory } from "./api-config";

import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { APP_BASE_HREF } from '@angular/common';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { BantaSdkModule } from "@banta/sdk";
import { AnalyticsModule } from '@tytapp/analytics';
import { AccountAlertsService } from '@tytapp/app/account-alerts.service';
import { ClientPersistenceService } from '@tytapp/app/client-persistence.service';
import { DevModule } from '@tytapp/app/dev';
import { ApiMiddlewareService, ApiReuse } from '@tytapp/app/middleware';
import { ApiStateTransfer } from '@tytapp/app/middleware/api-state-transfer';
import { NavLogoComponent } from '@tytapp/app/nav-logo/nav-logo.component';
import { OpenInMobileAppLinkHandlerComponent } from '@tytapp/app/open-in-mobile-app-link-handler/open-in-mobile-app-link-handler.component';
import { OpenInMobileAppComponent } from '@tytapp/app/open-in-mobile-app/open-in-mobile-app.component';
import { BasicSearchComponent } from '@tytapp/app/pages/search-pages/basic-search/basic-search.component';
import { SiteFooterComponent } from '@tytapp/app/site-footer/site-footer.component';
import { ArticlesModule } from '@tytapp/articles';
import { BillingModule } from '@tytapp/billing';
import { ChatModule } from "@tytapp/chat/chat.module";
import { CommonUiModule } from "@tytapp/common-ui";
import { RouteMatcher } from '@tytapp/common/route-matcher';
import { TermsAcceptanceService } from '@tytapp/common/terms-acceptance.service';
import { CommunityModule } from '@tytapp/community/community.module';
import { ContentModule } from '@tytapp/content';
import { CoreContentBlocksModule } from '@tytapp/core-content-blocks/core-content-blocks.module';
import { LiveStreamsModule } from '@tytapp/live-streams';
import { LiveUiModule } from '@tytapp/live-ui';
import { DownloadsService } from '@tytapp/media-playback';
import { MediaUiModule } from '@tytapp/media-ui';
import { NotificationsModule } from "@tytapp/notifications";
import { PluginsModule } from "@tytapp/plugins";
import { ShowsModule } from '@tytapp/shows';
import { SlugsModule } from '@tytapp/slugs';
import { environment } from "../environment";
import { PollsModule } from "../polls";
import { FeedItemComponent } from "./feed-item/feed-item.component";
import { FeedComponent } from "./feed/feed.component";
import { FinishOauthSignupComponent } from "./finish-oauth-signup/finish-oauth-signup.component";
import { NavComponent } from "./nav/nav.component";
import { RedirectionComponent } from "./redirection/redirection.component";
import { RerouteComponent } from './reroute.component';
import { CustomRouteReuseStrategy } from "./route-reuse";

@NgModule({
    declarations: [
        AppComponent,
        RerouteComponent,
        NavLogoComponent,

        /**
         * PAGES *********************
         */

        LogoutComponent,
        NavComponent,
        BasicSearchComponent,
        RedirectionComponent,
        FeedComponent,
        FeedItemComponent,
        FinishOauthSignupComponent,
        OpenInMobileAppComponent,
        OpenInMobileAppLinkHandlerComponent,
        SiteFooterComponent
    ],

    imports: [
        /* CORE */

        ApiModule.forRoot(),
        BrowserAnimationsModule,
        FormsModule,
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        MatSnackBarModule,
        MatBottomSheetModule,

        RouterModule.forRoot(ROUTES, {
            //preloadingStrategy: PreloadAllModules
            anchorScrolling: "enabled",
            scrollOffset: [0, 140],
            onSameUrlNavigation: "reload",
            scrollPositionRestoration: "top",
            initialNavigation: "enabledBlocking"
        }),
        BrowserModule,
        OverlayModule,
        //ServerTransition.forRoot({ appId: 'tytapp' }),

        /* THIRD PARTY */
        RecaptchaModule,
        RecaptchaFormsModule,
        BantaSdkModule.configure({
            serviceUrl: environment.urls.bantaServer,
            emojiUrl: `/assets/twemoji/`
        }),

        /* FEATURES */
        DevModule.forRoot(),
        BillingModule.forRoot(),
        ChatModule.forRoot(),
        NotificationsModule.forRoot(),
        TYTCommonModule.forRoot(),
        CommonUiModule.forRoot(),
        ArticlesModule.forRoot(),
        MediaPlaybackModule.forRoot(),
        UserModule.forRoot(),
        PollsModule.forRoot(),
        ContentModule.forRoot(),
        CoreContentBlocksModule,
        SlugsModule.forRoot(),
        ShowsModule.forRoot(),
        LiveStreamsModule.forRoot(),
        MediaUiModule,
        LiveUiModule,
        CommunityModule,
        AnalyticsModule,
        PluginsModule,
        ServiceWorkerModule.register("/ngsw-worker.js", {
            enabled: environment.enableServiceWorker,
        }),
        MatSlideToggleModule,
        MatButtonModule
    ],
    bootstrap: [AppComponent],
    //schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: APP_ID, useValue: 'tytapp' },
        { provide: APP_BASE_HREF, useValue: '/' },
        provideHttpClient(withFetch()),
        provideClientHydration(
            withHttpTransferCacheOptions({
                includeHeaders: ['X-Total', 'X-TYT-Total', 'X-TYT-Authentication']
            })
        ),
        AccountAlertsService,
        TermsAcceptanceService,
        DownloadsService,
        ApiMiddlewareService,
        ApiStateTransfer,
        ApiReuse,
        ClientPersistenceService,
        RouteMatcher,

        {
            provide: MAT_TABS_CONFIG,
            useValue: <MatTabsConfig>{
                disablePagination: false
            }
        },
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy, },
        { provide: ApiConfiguration, useFactory: ApiConfigurationFactory, deps: [] },
        { provide: ErrorHandler, useClass: AppErrorHandler },
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer }
    ]
})
export class AppModule {}
